<template>
  <section class="aishop" :style="{ marginTop: marginTop }">
    <!-- <div class="activity" v-show="isacticity">
      <img
        src="../../../assets/zh/images/shouye_huodong@2x.png"
        @click="RouteActicity()"
        alt=""
      />
      <div class="close" @click="close()">
        <span style="color: white" class="iconfont iconToast_closed"></span>
      </div>
    </div> -->
    <section class="banner">
      <figure class="buttons">
        <div class="button">
          <el-button
            class="unchecked"
            @click="RoutePush('/CooVallyHome')"
            style="width: 136px"
            >{{ $t("PC.Product.CooVallyButton.deaa52") }}</el-button
          >
          <el-button
            class="unchecked"
            style="width: 136px"
            @click="RoutePush('/CooVally')"
            >{{ $t("PC.Product.CooVallyButton.2446d2") }}</el-button
          >
          <el-button class="checked">{{
            $t("PC.Product.CooVallyButton.fad28a")
          }}</el-button>
          <!-- <el-button class="unchecked" @click="RoutePush('/ApplyTrial')">{{
            $t("PC.Product.CooVallyButton.ed4a49")
          }}</el-button> -->
        </div>
      </figure>

      <figure class="main">
        <div class="left">
          <div class="title" v-show="$t('locale') == 'zh'">
            <div class="strong"></div>
            <span
              >{{ $t("PC.Product.AISHOPNew.1d4fa3") }}
              <span class="color">{{ $t("PC.Product.AISHOPNew.70e312") }}</span
              >{{ $t("PC.Product.AISHOPNew.kocz12") }}</span
            >
          </div>

          <div
            class="title"
            style="margin-top: -30px"
            v-show="$t('locale') == 'en'"
          >
            <div class="strong_en"></div>
            <span
              >{{ $t("PC.Product.AISHOPNew.1d4fa3") }}
              <span class="color">{{ $t("PC.Product.AISHOPNew.70e312") }}</span
              >{{ $t("PC.Product.AISHOPNew.kocz12") }}</span
            >
          </div>
          <div class="text">
            <p>
              {{ $t("PC.Product.AISHOPNew.4d7bd7") }}
            </p>
          </div>
          <div class="contactus">
            <el-button class="contactusbutton" @click="GoCooVally()">
              {{ $t("PC.Product.CooVallyButton.ed4a49") }}</el-button
            >
          </div>
        </div>
        <div class="right">
          <div class="mainImg">
            <img
              src="../../../assets/images/CooVally/chahua@2x.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
        </div>
      </figure>

      <figure class="scene" v-show="$t('locale') == 'zh'">
        <!-- <div class="topSticky">
          <div class="topContent">
            <el-input
              v-model="inpVal"
              placeholder="请输入关键词"
              @keyup.enter.native="getscenelist(inpVal)"
            ></el-input>
          </div>
        </div> -->
        <div class="mallMain">
          <div class="sceneListBox">
            <div
              class="caseBlock"
              v-for="item in sceneList"
              :key="item.id"
              @click="GoDetail(item)"
            >
              <img :src="imgServeUrl + item.url" alt="" />

              <div class="caseContent">
                <div class="title">
                  <p>{{ item.scene_name }}</p>
                </div>
                <div class="text">
                  <el-tooltip placement="top" class="tooltip">
                    <div
                      class="tooltips"
                      slot="content"
                      style="width: 532px; max-height: 210px; line-height: 20px"
                    >
                      {{ item.brief_introduction }}
                    </div>
                    <div class="miaoshu">
                      {{ item.brief_introduction | ellipsis }}
                    </div>
                  </el-tooltip>
                </div>

                <div class="tags">
                  <span
                    class="tag"
                    v-for="(item, index) in item.labels.split(',')"
                    :key="index"
                    >{{ item }}</span
                  >
                  <span class="views">
                    <span class="iconfont icon-view"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </figure>

      <figure class="scene" v-show="$t('locale') == 'en'">
        <!-- <div class="topSticky">
          <div class="topContent">
            <el-input
              v-model="inpVal"
              placeholder="请输入关键词"
              @keyup.enter.native="getscenelist(inpVal)"
            ></el-input>
          </div>
        </div> -->
        <div class="mallMain">
          <div class="sceneListBox">
            <div
              class="caseBlock"
              v-for="item in sceneList"
              :key="item.id"
              @click="GoDetail(item)"
            >
              <img :src="imgServeUrl + item.url" alt="" />

              <div class="caseContent">
                <div class="title">
                  <p>{{ item.scene_name_en }}</p>
                </div>
                <div class="text">
                  <el-tooltip placement="top" class="tooltip">
                    <div
                      class="tooltips"
                      slot="content"
                      style="width: 532px; max-height: 210px; line-height: 20px"
                    >
                      {{ item.brief_introduction_en }}
                    </div>
                    <div class="miaoshu">
                      {{ item.brief_introduction_en | ellipsis_en }}
                    </div>
                  </el-tooltip>
                </div>

                <div class="tags">
                  <span
                    class="tag"
                    v-for="(item, index) in item.labels_en.split(',')"
                    :key="index"
                    >{{ item }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </figure>

      <FooterC></FooterC>
    </section>
  </section>
</template>

<script>
import FooterC from "../components/FooterC.vue";
export default {
  name: "Home",
  components: {
    FooterC,
  },

  data() {
    return {
      date: "",
      ContactUsVisible: false,
      sceneList: [],
      sceneCategoryId: "",
      style: "",
      style1: "",
      domIconArrow: "",
      tradeApplication: "",
      techCate: "",
      sceneList: undefined,
      inpVal: "",
      titleIndex: null,
      imgServeUrl: Global.IMG_SERVE,
      marginTop: "",
      isacticity: true,
    };
  },
  watch: {
    inpVal() {
      if (this.inpVal == "") {
        // console.log(this.sceneCategoryId);
        if (this.sceneCategoryId == "") {
          sceneList().then((res) => {
            this.sceneList = res.data.results;
          });
        } else {
          sceneList(this.sceneCategoryId, undefined).then((res) => {
            this.sceneList = res.data.results;
          });
        }
      }
    },
  },
  created() {
    this.getscenelist("");
    this.marginTop = "70px";
  },
  mounted() {
    this.date = new Date().getFullYear() + "";
    this.domIconArrow = document.getElementsByClassName(
      "el-submenu__icon-arrow"
    );
  },
  methods: {
    ContactUs() {
      this.ContactUsVisible = true;
    },
    routerGo(routeName) {
      const { href } = this.$router.resolve({
        name: routeName,
      });
      window.open(href);
    },
    newOpen(url) {
      window.open(url);
    },

    RoutePush(url) {
      this.$router.push({
        path: url,
      });
    },

    GoCooVally() {
      let url = Global.COOVALLY_URL;
      window.open(url, "_blank");
    },

    GoDetail(item) {
      let id = item.id;
      console.log(item);
      sessionStorage.setItem("details", JSON.stringify(item));
      if (sessionStorage.getItem("details")) {
        let url = "";
        switch (id) {
          case 133:
            url = "/WeldDefectDetection";
            break;
          case 134:
            url = "/TargetTracking";
            break;
          case 135:
            url = "/PostureDetection";
            break;
          case 136:
            url = "/ImageRestoration";
            break;
          case 125:
            url = "/SafetyHelmetDetection";
            break;
          case 124:
            url = "/MaskRecognition";
            break;
          case 128:
            url = "/GarbageRecognition";
            break;
          case 129:
            url = "/OCRRecognition";
            break;
          case 130:
            url = "/TextRecognition";
            break;
          case 131:
            url = "/LicensePlateRecognition";
            break;
          case 132:
            url = "/FaceRecognition";
            break;
          case 127:
            url = "/PCBDefectDetection";
            break;
          case 126:
            url = "/EleDefectDetection";
            break;
          case 137:
            url = "/SawBladeDefectDetection";
            break;
          case 138:
            url = "/ArtificialHeartValvesDefectDetection";
            break;
          case 139:
            url = "/HumanOrganCTDetection";
            break;
          case 140:
            url = "/SolarPanelDefectDetection";
            break;
          case 141:
            url = "/COVID19LesionsCTDetection";
            break;
          case 142:
            url = "/LungSegmentationCTDetection";
            break;
          case 143:
            url = "/HeartLungTracheaCTDetection";
            break;
          case 144:
            url = "/AutonomousDrivingTargetDetection";
            break;
          case 145:
            url = "/RoadDefectDetection";
            break;
          case 146:
            url = "/RoadVehicleDetection";
            break;
          default:
            url = "";
        }

        if (url != "") {
          this.$router.push({
            path: url,
          });
        } else {
          this.$message({
            message: "开发中",
            type: "warning",
          });
        }
      }
    },

    getscenelist(searchVal) {
      if (searchVal != "") {
        this.axios
          .get(
            Global.COOVALLY_URL +
              Global.GET_SCENE_LIST +
              "?scene_name=" +
              searchVal
          )
          .then((res) => {
            // console.log(res);
            if (res.status == 200 || res.status == 201) {
              this.sceneList = res.data.results;
            } else {
              this.$message({
                message: "请求出错",
                type: "error",
              });
            }
          })
          .catch((error) => {
            return error;
          });
      } else {
        this.axios
          .get(Global.COOVALLY_URL + Global.GET_SCENE_LIST + "?page_size=100")
          .then((res) => {
            // console.log(res);
            if (res.status == 200 || res.status == 201) {
              this.sceneList = res.data.results;
            } else {
              this.$message({
                message: "请求出错",
                type: "error",
              });
            }
          })
          .catch((error) => {
            return error;
          });
      }
    },

    RouteActicity() {
      let routeData = this.$router.resolve({ path: "/Acticity" });
      window.open(routeData.href, "_blank");
    },

    close() {
      this.marginTop = "70px";
      this.isacticity = false;
    },
  },

  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 24) {
        return value.slice(0, 24) + "...";
      }
      return value;
    },

    ellipsis_en(value) {
      if (!value) return "";
      if (value.length > 50) {
        return value.slice(0, 50) + "...";
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-popup-parent--hidden {
  overflow: hidden;
}

.aishop {
  margin-top: 70px;
  word-break: break-word !important;

  .activity {
    position: fixed;
    top: 70px;
    left: 0px;
    z-index: 9;
    img {
      width: 100%;
    }
    img:hover {
      cursor: pointer;
    }

    .close {
      z-index: 99;
      position: fixed;
      left: 98%;
      top: 12vh;
      // background: red;
      widows: 10px;
      height: 20px;
    }
  }

  .banner {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fafcff;
    background-image: url("../../../assets/images/CooVally/aibg@2x.png");
    background-size: 100% 22%;
    background-repeat: no-repeat;

    .buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      .button {
        display: flex;
        justify-content: space-between;
        width: 558px;
        padding-top: 48px;
        .checked {
          width: 180px;
          height: 60px;
          background-color: #4640de;
          color: #ffffff;
          border-radius: 4px;
          padding: 0 0 0 0;
          font-size: 20px;
        }
        .checked:hover {
          width: 180px;
          height: 60px;
          background: #4640de;
          // border-radius: 26px;
          opacity: 0.8;
        }

        .unchecked {
          width: 180px;
          height: 60px;
          background-color: #4568ee1a;
          color: #6d71f9;
          border-radius: 4px;
          padding: 0 0 0 0;
          font-size: 20px;
        }
        .unchecked:hover {
          width: 180px;
          height: 60px;
          background: #6d71f9;
          color: #ffffff;
          // border-radius: 26px;
          // opacity: 0.8;
        }
      }
    }

    .main {
      // background-color: green;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 3%;
      // z-index: 888;
      .left {
        // background-color: yellow;
        width: 527px;
        position: relative;

        .strong {
          position: absolute;
          top: 43px;
          left: 188px;
          width: 178px;
          height: 8px;
          box-shadow: 0px 8px 0px 0px rgba(#4640de, 0.2);
        }
        .strong_en {
          position: absolute;
          top: 100px;
          left: 0px;
          width: 478px;
          height: 8px;
          box-shadow: 0px 8px 0px 0px rgba(#4640de, 0.2);
        }

        .title {
          width: 558px;
          height: 62px;
          position: absolute;
          top: 91px;
        }
        span {
          font-size: 44px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2d3242;
          line-height: 62px;
        }
        .color {
          color: #6d71f9;
        }

        .text {
          width: 627px;
          height: 68px;
          position: absolute;
          top: 185px;
          p:nth-of-type(1) {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #636c78;
            line-height: 34px;
          }
        }
        .contactus {
          position: absolute;
          top: 293px;
          .contactusbutton {
            width: 186px;
            height: 55px;
            background: #6d71f9;
            border-radius: 4px;
            color: #fafcff;
            padding: 0 0 0 0;
            font-size: 20px;
          }
          .contactusbutton:hover {
            width: 186px;
            height: 55px;
            background: #6d71f9;
            // border-radius: 26px;
            opacity: 0.8;
          }
        }
      }

      .right {
        // background-color: white;
        width: 673px;
        height: 563px;
        .mainImg {
          position: relative;
          left: 40px;
          margin: 0 auto;
          width: 673px;
          height: 563px;

          // margin-top: -5.6%;
          img {
          }
        }
      }
    }

    //场景
    .scene {
      height: 100%;
      background-color: #fafcff;
      .topSticky {
        border-bottom: 1px solid #fafcff;
        position: sticky;
        background-color: #fafcff;
        top: 0;
        z-index: 1;
        // background-color: green;
        div.topContent {
          // background-color: red;
          margin: 0 auto;
          width: 1200px;
          min-width: 1200px;
          height: 97px;
          position: relative;

          /deep/ .el-input__prefix {
            width: 16px;
            height: 16px;
            left: 10px;
            top: 10px;
          }
          // 下拉菜单外框
          div.downBox1 {
            z-index: 999;
            margin-top: 102px;
            padding: 22px 0 20px 30px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            background-color: #fafcff;
            box-shadow: 0px 5px 5px 0px rgba(148, 161, 195, 0.2);
            transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
            -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
            transform-origin: center top;
            -webkit-transform-origin: center top;
            height: auto;
            width: 100%;
            opacity: 0;
            flex-wrap: wrap;

            span {
              flex-wrap: wrap;
              display: inline-block;
              margin: 0 40px 10px 0;
              font-size: 14px;
              color: #fafcff;
              line-height: 20px;

              &:hover {
                color: #2a75fa;
                cursor: pointer;
              }
            }

            .span-active {
              color: #2a75fa;
            }
          }
          // 顶部搜索框
          .el-input {
            width: 270px;
            float: left;
            right: 5px;
            height: 38px;
            margin-top: 32px;
            position: absolute;
            &::before {
              position: absolute;
              top: 8px;
              right: 20px;
              font-size: 20px;
              color: #fafcff;
            }

            /deep/ .el-input__inner {
              padding-left: 30px;
              width: 100%;

              height: 100%;

              border-radius: 0;

              &:focus {
                border-color: #2a75fa;
              }
            }
          }

          // 顶部菜单触发按钮
          .el-menu-demo {
            display: inline-block;
            width: auto;
            height: 100%;
            border: 0;
            background-color: #fafcff;
            div.btnBox {
              padding-top: 20px;
              background-color: transparent !important;
              outline-width: 0 !important;

              .title_span {
                font-size: 16px;
                font-weight: 900;
                color: #1a2950;
              }
            }
          }

          .el-menu-demo:nth-of-type(1) {
            margin-left: 65px;
          }
        }
      }
      .mallMain {
        width: 1210px;
        margin: 22px auto 100px;
        padding-top: 20px;

        .sceneListBox {
          flex-wrap: wrap;
          min-height: 488px;
          display: flex;
          justify-content: start;
          align-items: flex-start;
          // justify-content: center;
          .caseBlock {
            cursor: pointer;
            width: 384px;
            margin: 12px 12px;
            background-color: #fafcff;
            box-shadow: 0px 2px 10px 0px rgba(52, 62, 76, 0.08);
            border-radius: 4px;
            position: relative;
            bottom: 0;
            transition: all 200ms linear;
            -webkit-transition: all 200ms linear;

            &:hover {
              bottom: 6px;
              box-shadow: 0px 8px 14px 0px rgba(52, 62, 76, 0.08);
            }

            img {
              width: 383.56px;
              height: 306px;
              border-radius: 4px 4px 0px 0px;
            }

            div.caseContent {
              padding: 0 12px 12px;
              box-sizing: border-box;
              height: 153px;
              // background-color: green;
              .title {
                position: relative;
                top: 13px;
                width: 360px;
                height: 25px;

                p:nth-of-type(1) {
                  font-size: 18px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #2d3242;
                  line-height: 25px;
                }
              }

              .text {
                position: relative;
                top: 33px;
                width: 366px;
                height: 20px;
                .miaoshu {
                  width: 360px;
                  height: 20px;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #636c78;
                  line-height: 20px;

                  text-overflow: -o-ellipsis-lastline;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  line-clamp: 2;
                  -webkit-box-orient: vertical;
                  word-break: break-all;
                }
              }

              .tags {
                font-size: 12px;
                color: #429fff;
                position: relative;
                top: 63px;

                .tag {
                  display: inline-block;
                  margin-right: 0px;
                  line-height: initial;
                  padding: 4px 3px;
                  color: #429fff;
                  background-color: #ecf5ff;
                  border-radius: 2px;
                  border: 1px solid #d9ebff;
                  transform: scale(0.85);
                  transform-origin: left top;
                }

                .views {
                  position: absolute;
                  right: 0;

                  color: #666666;
                  line-height: 17px;

                  span {
                    height: 100%;
                    font-size: 12px;
                    display: inline-block;
                    vertical-align: top;
                  }

                  .viewsNum {
                    max-width: 44px;
                    overflow: hidden;
                    white-space: nowrap;
                    word-break: break-all;
                  }

                  .icon-view {
                    font-size: 17px;
                    margin-right: 2px;
                  }
                }
              }
            }
          }

          // 左右边距
          .caseBlock:nth-of-type(3n) {
            margin-right: 5px;
          }

          .caseBlock:nth-of-type(3n + 1) {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
